import React, { useState } from 'react';
import { Layout, Typography, Input, Button, Card, Descriptions } from 'antd';
import 'antd/dist/reset.css'; // Ensure you're using Ant Design's updated CSS

const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;

function App() {
  const [ediData, setEdiData] = useState('');
  const [parsedPO, setParsedPO] = useState(null);

  // Updated EDI 850 Parser
  const parseEDI850 = (ediText) => {
    try {
      const lines = ediText.trim().split(/~\s*/); // Split EDI data by '~' instead of '\n'
      let poNumber = '';
      let poDate = '';
      let buyerName = '';
      let supplierName = '';
      let items = [];

      // Loop through each line to extract EDI information
      lines.forEach((line) => {
        const segments = line.split('*'); // Split each segment by '*'

        switch (segments[0]) {
          case 'BEG': // Beginning Segment for the Purchase Order
            poNumber = segments[3] || 'N/A'; // Purchase Order Number
            poDate = segments[5] || 'N/A'; // PO Date (YYYYMMDD format)
            break;

          case 'N1': // Name Information
            if (segments[1] === 'BY') {
              buyerName = segments[2] || 'N/A'; // Buyer Name
            }
            if (segments[1] === 'ST') {
              supplierName = segments[2] || 'N/A'; // Supplier Name
            }
            break;

          case 'PO1': // Line Items
            const item = {
              name: segments[11] || 'Unknown Item', // Using correct position for the product description or ID
              quantity: segments[2] || '0', // Quantity Ordered
              price: segments[4] || '0.00', // Unit Price
            };
            items.push(item);
            break;

          default:
            break;
        }
      });

      // Return the parsed Purchase Order
      return {
        poNumber,
        poDate,
        buyerName,
        supplierName,
        items,
      };
    } catch (error) {
      console.error('Error parsing EDI 850 data:', error);
      alert('There was an error parsing the EDI data. Please check the format.');
      return null;
    }
  };

  const handleParseClick = () => {
    // Ensure we're working with the actual entered data
    if (ediData.trim() === '') {
      alert("Please enter EDI 850 data before parsing!");
      return;
    }

    const parsed = parseEDI850(ediData);
    setParsedPO(parsed);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ backgroundColor: '#1890ff', padding: '0 20px' }}>
        <Title level={2} style={{ color: '#fff', margin: '0' }}>
          EDI 850 Purchase Order Viewer
        </Title>
      </Header>
      <Content style={{ padding: '20px' }}>
        <Card style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
          <Title level={3}>Paste Your EDI 850 (New PO)</Title>
          <TextArea
            rows={6}
            value={ediData}
            onChange={(e) => setEdiData(e.target.value)}
            placeholder="Paste your raw EDI 850 data here..."
          />
          <Button
            type="primary"
            style={{ marginTop: '10px', width: '100%' }}
            onClick={handleParseClick}
          >
            Parse and View Purchase Order
          </Button>
        </Card>

        {parsedPO && (
          <Card
            style={{
              marginTop: '20px',
              maxWidth: '800px',
              margin: '20px auto',
              padding: '20px',
            }}
          >
            <Descriptions
              title="Purchase Order Details"
              bordered
              column={1}
              style={{ backgroundColor: '#fff' }}
            >
              <Descriptions.Item label="PO Number">
                {parsedPO.poNumber || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Order Date">
                {parsedPO.poDate || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Buyer Name">
                {parsedPO.buyerName || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Supplier Name">
                {parsedPO.supplierName || "N/A"}
              </Descriptions.Item>
            </Descriptions>

            <Title level={4} style={{ marginTop: '20px' }}>
              Items
            </Title>
            {parsedPO.items.length > 0 ? (
              parsedPO.items.map((item, index) => (
                <Card key={index} style={{ marginBottom: '10px' }}>
                  <Text>
                    <strong>{item.name}</strong> - {item.quantity} pcs -{' '}
                    {item.price}
                  </Text>
                </Card>
              ))
            ) : (
              <Text>No items found.</Text>
            )}
          </Card>
        )}
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        EDI 850 Purchase Order Viewer �2024
      </Footer>
    </Layout>
  );
}

export default App;
